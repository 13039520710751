import { InputLabel, Select, MenuItem, SelectChangeEvent, CircularProgress } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import CustomTableHeader from '../common/components/table/tableHeader/CustomTableHeader';
import { JeeContext } from './context/jee';
import JeeFilterIcon from './JEEFilterIcon';
import useGetRounds from './query/useGetRounds';
import useSelectedCounsellingOption from './hooks/useSelectedCounsellingOption';
import { CSAB, CSAB_CONFIG, JOSAA_CONFIG } from './constants';

interface Props {
    openFilterDialog: () => void;
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>;
    round: number;
    setRound : React.Dispatch<React.SetStateAction<number>>;
    counsellingOption: number;
    setCounsellingOption: React.Dispatch<React.SetStateAction<number>>;
}
const JeeTableHeader: React.FC<Props> = ({openFilterDialog, year, setYear, round, setRound, counsellingOption, setCounsellingOption}) => {
    const {counselling} = useContext(JeeContext)
    const selectedCounsellingOption = useSelectedCounsellingOption();
    const [rounds, isRoundsLoading] = useGetRounds();
    const yearsOfData = selectedCounsellingOption.option === CSAB ?  CSAB_CONFIG.yearsOfData : JOSAA_CONFIG.yearsOfData;

    useEffect(() => {
        if (!isRoundsLoading) {
            let maxRound = 1;
            rounds?.rows.forEach(round => {
                maxRound = maxRound < round ? round : maxRound
            });
            setRound(maxRound);
        }
    }, [rounds, isRoundsLoading]);

    useEffect(() => {
        let maxYear = 0;
        yearsOfData.forEach(year => maxYear = maxYear < year ? year : maxYear);
        setYear(prev => prev > maxYear ? maxYear : prev);
    }, [yearsOfData[0]]);

    const handleYearChange = (e: SelectChangeEvent<number>) => {
        setYear(e.target.value as number);
    }

    const handleCounsellingChange = (e: SelectChangeEvent<number>) => {
        setCounsellingOption(e.target.value as number);
    }

    const handleRoundChange = (e: SelectChangeEvent<number>) => {
        setRound(e.target.value as number);
    }

  return (
    <CustomTableHeader filterIcon={<JeeFilterIcon openFilterDialog={openFilterDialog} />}>
        <InputLabel htmlFor="year" sx={{marginRight: 1}}>Year: </InputLabel>
        <Select
            variant="standard"
            id="year"
            name="year"
            value={year}
            onChange={handleYearChange}
            multiple={false}
        >
            {yearsOfData.map((option) => (
            <MenuItem key={option} value={option}>
                {option}
            </MenuItem>
            ))}
        </Select>
        <InputLabel htmlFor="counselling" sx={{marginLeft: 2, marginRight: 1}}>Counselling: </InputLabel>
        <Select
            variant="standard"
            id="counselling"
            name="counselling"
            value={counsellingOption}
            onChange={handleCounsellingChange}
            multiple={false}
        >
            {counselling.isLoading ? <CircularProgress /> : counselling.options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.name.toUpperCase()}
            </MenuItem>
            ))}
        </Select>
        <InputLabel htmlFor="round" sx={{marginLeft: 2, marginRight: 1}}>Round: </InputLabel>
        <Select
            variant="standard"
            id="round"
            name="round"
            value={round}
            onChange={handleRoundChange}
            multiple={false}
        >
            {isRoundsLoading ? <CircularProgress /> : rounds?.rows.map((round) => (
            <MenuItem key={round} value={round}>
                {round}
            </MenuItem>
            ))}
        </Select>
    </CustomTableHeader>
  )
}

export default JeeTableHeader;